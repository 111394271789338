
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as apxHNkJeXi4LMeta } from "/app/pages/ap.vue?macro=true";
import { default as forgotNziWY5IfaeMeta } from "/app/pages/forgot.vue?macro=true";
import { default as createf5s5DwAuX2Meta } from "/app/pages/guest/create.vue?macro=true";
import { default as iconsAVqGQpFjv7Meta } from "/app/pages/icons.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as rapidPbK4vmqDKwMeta } from "/app/pages/rapid.vue?macro=true";
import { default as companymn6bSera9uMeta } from "/app/pages/secure/account/company.vue?macro=true";
import { default as membersj0PB6i98usMeta } from "/app/pages/secure/account/members.vue?macro=true";
import { default as profileTz1fso9NbGMeta } from "/app/pages/secure/account/profile.vue?macro=true";
import { default as securityS7kxb2iQEEMeta } from "/app/pages/secure/account/security.vue?macro=true";
import { default as createbE2nW5ONAEMeta } from "/app/pages/secure/create.vue?macro=true";
import { default as dashboardhutafg3dmLMeta } from "/app/pages/secure/dashboard.vue?macro=true";
import { default as indexOa5WLeOM4AMeta } from "/app/pages/secure/index.vue?macro=true";
import { default as indexESzK7aGa0JMeta } from "/app/pages/secure/invoices/index.vue?macro=true";
import { default as _91id_938kHlNBW4V2Meta } from "/app/pages/secure/projects/[id].vue?macro=true";
import { default as indexeUkJKDsrF2Meta } from "/app/pages/secure/projects/index.vue?macro=true";
import { default as _91id_939CD2r2X6n4Meta } from "/app/pages/secure/quotes/[id].vue?macro=true";
import { default as indexLdnizgkY94Meta } from "/app/pages/secure/quotes/index.vue?macro=true";
import { default as templatesEB5DgJAyeGMeta } from "/app/pages/secure/templates.vue?macro=true";
import { default as utilsoKlxMvFT6UMeta } from "/app/pages/secure/utils.vue?macro=true";
export default [
  {
    name: "ap___en",
    path: "/ap",
    component: () => import("/app/pages/ap.vue")
  },
  {
    name: "forgot___en",
    path: "/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    component: () => import("/app/pages/forgot.vue")
  },
  {
    name: "RapidRequestProject___en",
    path: "/guest/create",
    meta: createf5s5DwAuX2Meta || {},
    component: () => import("/app/pages/guest/create.vue")
  },
  {
    name: "icons___en",
    path: "/icons",
    meta: iconsAVqGQpFjv7Meta || {},
    component: () => import("/app/pages/icons.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "rapid___en",
    path: "/rapid",
    component: () => import("/app/pages/rapid.vue")
  },
  {
    name: "secure-account-company___en",
    path: "/secure/account/company",
    meta: companymn6bSera9uMeta || {},
    component: () => import("/app/pages/secure/account/company.vue")
  },
  {
    name: "secure-account-members___en",
    path: "/secure/account/members",
    meta: membersj0PB6i98usMeta || {},
    component: () => import("/app/pages/secure/account/members.vue")
  },
  {
    name: "secure-account-profile___en",
    path: "/secure/account/profile",
    meta: profileTz1fso9NbGMeta || {},
    component: () => import("/app/pages/secure/account/profile.vue")
  },
  {
    name: "secure-account-security___en",
    path: "/secure/account/security",
    meta: securityS7kxb2iQEEMeta || {},
    component: () => import("/app/pages/secure/account/security.vue")
  },
  {
    name: "secure-create___en",
    path: "/secure/create",
    meta: createbE2nW5ONAEMeta || {},
    component: () => import("/app/pages/secure/create.vue")
  },
  {
    name: "secure-dashboard___en",
    path: "/secure/dashboard",
    meta: dashboardhutafg3dmLMeta || {},
    component: () => import("/app/pages/secure/dashboard.vue")
  },
  {
    name: "secure___en",
    path: "/secure",
    meta: indexOa5WLeOM4AMeta || {},
    component: () => import("/app/pages/secure/index.vue")
  },
  {
    name: "secure-invoices___en",
    path: "/secure/invoices",
    meta: indexESzK7aGa0JMeta || {},
    component: () => import("/app/pages/secure/invoices/index.vue")
  },
  {
    name: "secure-projects-id___en",
    path: "/secure/projects/:id()",
    meta: _91id_938kHlNBW4V2Meta || {},
    component: () => import("/app/pages/secure/projects/[id].vue")
  },
  {
    name: "secure-projects___en",
    path: "/secure/projects",
    meta: indexeUkJKDsrF2Meta || {},
    component: () => import("/app/pages/secure/projects/index.vue")
  },
  {
    name: "secure-quotes-id___en",
    path: "/secure/quotes/:id()",
    meta: _91id_939CD2r2X6n4Meta || {},
    component: () => import("/app/pages/secure/quotes/[id].vue")
  },
  {
    name: "secure-quotes___en",
    path: "/secure/quotes",
    meta: indexLdnizgkY94Meta || {},
    component: () => import("/app/pages/secure/quotes/index.vue")
  },
  {
    name: "secure-templates___en",
    path: "/secure/templates",
    meta: templatesEB5DgJAyeGMeta || {},
    component: () => import("/app/pages/secure/templates.vue")
  },
  {
    name: "secure-utils___en",
    path: "/secure/utils",
    meta: utilsoKlxMvFT6UMeta || {},
    component: () => import("/app/pages/secure/utils.vue")
  }
]