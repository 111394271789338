import revive_payload_client_Q8OLdJjpip from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uNZmY0qmWp from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qOkB6JrrbZ from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RmIlU6ivfa from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BjXEnpoEOu from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BnLO61EmtP from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fvJnUmbBaq from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_uWUamSjxEP from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@4.9.5_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5POim05O6W from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_DDFmY7YkWE from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_vue@3.5.13/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_client_eNelkHx7DD from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_5UCQbbpdjZ from "/app/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.66.0_nuxt@3.15.4/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import composition_ZQk7jqxl8a from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.9_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_UFwAGWvzmW from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.9_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_wvrqFOmNU9 from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.3_@azure+storage-blob@12.26.0_next-auth@4.21.1_typescript@4.9.5/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_NtRTFuAq6g from "/app/node_modules/.pnpm/nuxt-icon@1.0.0-beta.5_vite@4.5.9_vue@3.5.13/node_modules/nuxt-icon/dist/runtime/plugin.js";
import appState_6zDlVSUkMw from "/app/plugins/appState.ts";
import autoanimate_XXcG14nh7J from "/app/plugins/autoanimate.ts";
import casl_QTBkbB6FCs from "/app/plugins/casl.ts";
import grid_client_svLOeFmqDG from "/app/plugins/grid.client.ts";
import highCharts_client_NZqQlQ5SOs from "/app/plugins/highCharts.client.ts";
import novu_client_AMvynteNKX from "/app/plugins/novu.client.ts";
import persistedstate_o0qkr1uoQ4 from "/app/plugins/persistedstate.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_Q8OLdJjpip,
  unhead_uNZmY0qmWp,
  router_qOkB6JrrbZ,
  payload_client_RmIlU6ivfa,
  navigation_repaint_client_BjXEnpoEOu,
  check_outdated_build_client_BnLO61EmtP,
  chunk_reload_client_fvJnUmbBaq,
  plugin_vue3_uWUamSjxEP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5POim05O6W,
  primevue_plugin_egKpok8Auk,
  plugin_client_DDFmY7YkWE,
  plugin_client_eNelkHx7DD,
  plugin_5UCQbbpdjZ,
  composition_ZQk7jqxl8a,
  i18n_UFwAGWvzmW,
  plugin_wvrqFOmNU9,
  formkitPlugin_pZqjah0RUG,
  plugin_NtRTFuAq6g,
  appState_6zDlVSUkMw,
  autoanimate_XXcG14nh7J,
  casl_QTBkbB6FCs,
  grid_client_svLOeFmqDG,
  highCharts_client_NZqQlQ5SOs,
  novu_client_AMvynteNKX,
  persistedstate_o0qkr1uoQ4,
  sentry_client_shVUlIjFLk
]