import validate from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.15.4_@azure+storage-blob@12.26.0_eslint@9.19.0_typescript@4.9.5_vite@4.5.9/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.3_@azure+storage-blob@12.26.0_next-auth@4.21.1_typescript@4.9.5/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}