<script setup lang="ts">
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { useConfirm } from 'primevue/useconfirm'
import { format } from 'date-fns'
import AzureBlob from '@/utils/AzureBlob'
import { useAuthStore } from '@/stores/authStore'
import delay from '@/helpers/delay'

const props = defineProps({
  context: Object,
  modelValue: Object,
  showLanguages: { type: Boolean, default: true },
  cloudUpload: { type: Boolean, default: true },
  endpoint: { type: String, default: 'files' },
  moduleId: { type: [String, Number], default: null },
  externalUpload: { type: Boolean, default: false },
  extAllowed: {
    type: Array,
    default: () => [
      'image/*',
      'text/*',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/pdf',
      'application/zip',
      'application/vnd.rar',
      'audio/*',
      'video/*',
      'application/x-indesign',
      'application/vnd.ms-excel.sheet.macroEnabled.12', // Additional Excel format (xlsm)
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    ],
  },
  extWarning: { type: Array, default: () => [] },
  maxFileSize: { type: Number, default: 100 },
})

const { t } = useI18n()
// const emit = defineEmits(['update:modelValue', 'beforeUpload', 'complete', 'filesAdded', 'beforeFileAdded'])

const attrs = computed(() => {
  return props.context?.attrs
})
const languageOptions = computed(() => {
  return props.context?.languages
})
const extWarning = computed(() => {
  return props.context?.extWarning ?? []
})

const maxFileSize = computed(() => {
  return props.context?.maxFileSize ?? 100
})

const authStore = useAuthStore()
const { data: authData } = useAuth()
const { accessToken } = storeToRefs(authStore)
const { get } = useApi()

const { errorToast } = useToast()

const config = useRuntimeConfig()
const confirm = useConfirm()
const { isDemoMode } = useDemoStore()

const apiUrl = config.public.apiUrl

const ptOptions = ref({
  buttonbar: {
    class: 'flex justify-center',
  },
  header: {
    class: 'hidden',
  },
  input: {
    class: 'hidden',
  },
  content: {
    class: 'relative border border-gray-200 text-gray-700 mt-4',
  },
})

const selectedFiles: Ref<GenericObjectI[]> = ref([])
const filesUploading: Ref<Record<string, number>> = ref({})

const uploadInput = ref()
const hasWarningFiles = ref(false)
const uppy = new Uppy({
  allowMultipleUploadBatches: true,
  restrictions: {
    maxFileSize: null,
    minFileSize: null,
    maxTotalFileSize: null,
    maxNumberOfFiles: null,
    minNumberOfFiles: null,
    allowedFileTypes: props.extAllowed as string[],
    requiredMetaFields: [],
  },
  meta: {
    reference: props.moduleId,
  },
  // debug: true,
  // onBeforeFileAdded: () => true,
  onBeforeFileAdded: (file, files) => {
    if (!props.externalUpload) {
      return true
    }

    // emit('beforeFileAdded', files)
    return false
  },
})

// uppy external upload

const todaysDate = format(new Date(), 'MM-dd-yyyy')
const azureFilePath = `${authData.value?.customer?.id}/${authData.value?.user?.id}/${todaysDate}`
if (props.cloudUpload) {
  uppy.use(AzureBlob, {
    endpoint: `https://avantusqa.blob.core.windows.net/avantportal`,
    // question mark infront of the sas token is mandatory
    sas: '?sp=rcwl&st=2025-01-29T16:20:49Z&se=2026-06-25T00:20:49Z&spr=https&sv=2022-11-02&sr=c&sig=yD0W3tuOeKMUoaHGBniAE0GjYESQu%2F5yZlBrOOxQvVc%3D',
    container: azureFilePath,
  })
}
else {
  uppy.use(XHRUpload, {
    endpoint: `${apiUrl}/${attrs.value?.endpoint ?? 'files'}`,
    fieldName: 'file',
    formData: true,
    allowedMetaFields: ['reference'],
    headers: (file) => {
      return {
        authorization: `bearer ${accessToken.value}`,
      }
    },
    timeout: 300000,
    limit: 10,
    responseUrlFieldName: 'data',
  })
}

// uppy-events
uppy.on('files-added', async (files) => {
  if (!extWarning.value.length) {
    // emit('filesAdded', files)
    return processUpload()
  }

  const warningFiles = files.filter((file) => {
    return extWarning.value.includes(file.extension)
  })
  if (warningFiles.length > 0) {
    await confirm.require({
      group: 'warningDialog',
      message: t('request.uneditable-message'),
      header: t('notify.warning'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: t('shared.actions.proceed'),
      rejectLabel: t('request.upload-editable'),
      acceptClass: 'p-button-danger p-button-text',
      accept: async () => {
        // emit('filesAdded', files)
        uppy.upload()

        hasWarningFiles.value = true
        confirm.close()
        return processUpload()
      },
      reject: () => {
        for (const file of warningFiles) {
          removeFile(file)
        }

        selectedFiles.value = uppy.getFiles()
        // emit('filesAdded', uppy.getFiles())
        uppy.upload()

        confirm.close()
        chooseFile()
        return processUpload()
      },
      onHide: () => {
        for (const file of warningFiles) {
          removeFile(file)
        }

        // emit('filesAdded', uppy.getFiles())
        uppy.upload()
        selectedFiles.value = uppy.getFiles()
        confirm.close()
        return processUpload()
      },
    })
  }
  else {
    // emit('filesAdded', files)
    uppy.upload()
    return processUpload()
  }
})
  .on('upload-error', async (file, error) => {
    await get('ping')
    errorToast(t('notify.error.upload'))
  })
  .on('upload-progress', (file, progress) => {
    if (!file) {
      return
    }

    filesUploading.value[file.id] = Math.trunc((100 * progress.bytesUploaded) / progress.bytesTotal)
  })
  .on('complete', (result) => {
    selectedFiles.value = uppy.getFiles()
    if (props.cloudUpload)
      selectedFiles.value = selectedFiles.value.map(item => ({ ...item, azurePath: `${azureFilePath}/${item?.name}` }))
    // emit('complete', result)
    // emit('update:modelValue', selectedFiles.value)
    props.context?.node?.input(selectedFiles.value)
    filesUploading.value = {}
  })

function handleLanguageSelection(val: string[], file: GenericObjectI) {
  if (!val || isDemoMode) {
    return
  }

  const existingFiles = [...props.context?.value]
  const fileIndex = existingFiles.findIndex(item => item.id === file.id)
  const respectiveFile = existingFiles.splice(fileIndex, 1)[0]
  respectiveFile.fileLanguages = val.length ? val : null
  props.context?.node?.input([...existingFiles, respectiveFile])
}

const contextFiles = computed(() => {
  const files = props.context?.value ?? props.context?._value
  if (!files) {
    return null
  }

  return files.map(file => ({ ...file, progress: { uploadComplete: true, percentage: 100 } }))
})

watch(contextFiles, (newValue, oldValue) => {
  // if there is a clear in a parent component
  if ((!newValue || !newValue.length) && oldValue.length) {
    clearAll()
  }

  if ((newValue && newValue.length) && (!oldValue || !oldValue.length)) {
    selectedFiles.value = newValue
  }
})

async function processUpload() {
  if (isDemoMode) {
    selectedFiles.value = uppy.getFiles()
    await incrementProgressManual()
    return
  }
  await get('ping')
  return uppy.upload()
}

// This function is to simulate the file upload progress in demo mode accounts
async function incrementProgressManual() {
  let stopCondition = false
  while (!stopCondition) {
    for (const file of selectedFiles.value) {
      if (file.progress?.percentage < 100 && !filesUploading.value[file.id]) {
        file.progress.percentage = 25
        filesUploading.value[file.id] = 25
      }
      else {
        file.progress.percentage += 25
        filesUploading.value[file.id] += 25
      }

      if (file.progress?.percentage >= 100) {
        file.progress.uploadComplete = true
        filesUploading.value[file.id] = undefined
      }
      await delay(400)
    }
    stopCondition = selectedFiles.value.every(uppyFile => uppyFile.progress?.percentage === 100)
  }
}

async function onSelectedFiles({ files }: { files: File[] }) {
  if (!files.length) {
    return
  }
  uppy.addFiles(files)
  selectedFiles.value = uppy.getFiles()
  uploadInput.value.clear()
  uploadInput.value.files = []
}

function chooseFile() {
  uploadInput.value.choose()
}

function removeFile(file: GenericObjectI) {
  uppy.removeFile(file.id)
  selectedFiles.value = uppy.getFiles()
  // emit('update:modelValue', selectedFiles.value)
  props.context?.node?.input(selectedFiles.value)
  delete filesUploading.value[file.id]
}

function clearAll() {
  for (const file of selectedFiles.value) {
    uppy.removeFile(file.id)
  }

  selectedFiles.value = uppy.getFiles()
  props.context?.node?.input(selectedFiles.value)
  filesUploading.value = {}
}

function isFileUploaded(file) {
  return file.progress?.uploadComplete && file.progress?.percentage === 100
}

function retryUpload(file) {
  uppy.retryUpload(file.id)
  selectedFiles.value = uppy.getFiles()
}
</script>

<template>
  <!-- TODO: stylish (confirm dialog buttons important) -->
  <div>
    <pv-confirm-dialog group="warningDialog" />
    <pv-file-upload
      ref="uploadInput"
      :pt="ptOptions"
      :show-upload-button="false"
      :show-cancel-button="false"
      :multiple="true"
      :max-file-size="maxFileSize * 1048576"
      :accept="props.extAllowed.toString()"
      invalid-file-type-message="Invalid file type"
      @select="onSelectedFiles"
    >
      <template #empty>
        <div
          v-if="!selectedFiles.length"
          class="flex flex-col items-center bg-slate-50 justify-center cursor-pointer p-8"
          @click="chooseFile"
        >
          <Icon
            name="ap:upload-cloud-02"
            class="text-slate-600 w-8 h-8"
          />
          <p><span class="font-bold">{{ t('projects.click-upload') }}</span> {{ t('projects.drag-drop') }}.</p>
          <p class="font-bold">
            {{ t('notify.max-size', { size: `${maxFileSize}MB` }) }}
          </p>
        </div>
      </template>
      <template #content>
        <div v-if="selectedFiles.length">
          <div class=" border-b border-slate-200 ">
            <table class="min-w-full divide-y divide-slate-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-3  py-3.5 text-left font-normal text-slate-500"
                  >
                    {{ t('shared.table.file-name') }}
                  </th>
                  <th
                    scope="col"
                    class=" px-3  py-3.5 text-left font-normal text-slate-500"
                  >
                    {{ t('shared.type') }}
                  </th>
                  <th
                    scope="col"
                    class=" px-3 py-3.5 text-left font-normal text-slate-500"
                  >
                    Languages
                  </th>
                  <th
                    scope="col"
                    class=" px-3 py-3.5 text-left font-normal text-slate-500"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class=" px-3  py-3.5 text-center font-normal text-slate-500"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 ">
                <tr
                  v-for="file in selectedFiles"
                  :key="file.id"
                >
                  <td class="whitespace-nowrap text-wrap py-4 pl-3 pr-3 text-md ">
                    {{ file.name }}
                  </td>
                  <td class="whitespace-nowrap text-wrap py-4 pl-3 pr-3 text-md ">
                    <pv-tag
                      :severity="extWarning.includes(file.extension) ? 'danger' : 'success'"
                      :value="extWarning.includes(file.extension) ? t('shared.non-editable') : t('shared.editable')"
                    />
                  </td>
                  <td class="whitespace-nowrap text-wrap py-4 pl-3 pr-3 text-md ">
                    <FormKit
                      name="fileLanguage"
                      type="group"
                    >
                      <FormKit
                        :name="file.id"
                        type="dropdown"
                        :options="languageOptions"
                        :multiple="true"
                        label=""
                        :placeholder="t('request.all-languages-selected')"
                        open-on-click
                        @input="handleLanguageSelection($event, file)"
                      />
                    </FormKit>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-3 pr-3 text-md ">
                    <pv-progress-bar
                      v-if="file.progress?.percentage < 100"
                      :value="filesUploading?.[file.id] ?? 0"
                    />
                    <Icon
                      v-else-if="isFileUploaded(file)"
                      name="ap:check-circle"
                      class="text-primary w-8 h-8 min-w-[1.75rem] rounded-full"
                    />
                    <pv-button
                      v-else
                      v-tooltip="t('notify.error.upload-clickAgain')"
                      icon="pi pi-replay"
                      text
                      :label="t('shared.actions.retry')"
                      severity="danger"
                      @click="retryUpload(file)"
                    />
                  </td>
                  <td class="whitespace-nowrap text-wrap py-4 pl-3 pr-3 text-md ">
                    <div class="flex justify-center">
                      <pv-button
                        severity="plain"
                        outlined
                        @click="removeFile(file)"
                      >
                        <Icon
                          name="ap:trash-01"
                          class="!text-red-500 w-5 h-5"
                        />
                      </pv-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="selectedFiles.length"
            class="py-4 px-6 flex items-center justify-between flex-row-reverse"
          >
            <div class="flex gap-3 flex-wrap">
              <pv-button
                outlined
                severity="plain"
                :label="t('shared.actions.clear-all')"
                @click="clearAll"
              />
              <pv-button
                :label="t('shared.actions.upload-files')"
                @click="chooseFile"
              />
            </div>
            <pv-message
              v-if="hasWarningFiles"
              :closable="false"
              severity="warn"
            >
              {{ t('request.uneditable-note') }}
            </pv-message>
          </div>
        </div>
      </template>
    </pv-file-upload>
  </div>
</template>
